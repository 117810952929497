import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'app-landing-page',
  standalone: true,
  imports: [CommonModule, RouterModule, HeaderComponent],
  templateUrl: './landing-page.component.html',
  styleUrl: './landing-page.component.scss'
})
export class LandingPageComponent implements OnInit {
  cardList = ['Industry Specific definition of Confidential Information to give you 100% coverage',
    "Verify the parties on government database. Start with 100% trust.", "Helpful tutorials to make quick decisons.",
    "Flexible Dispute Resolution", "Seamless E-Signature Integration"
  ]

 /*  cardContent = [' Rest assured of document authenticity by cross-referencing user-provided data with official government databases, enhancing trust and security.',
    'Access a curated list of confidential data tailored to your industry, ensuring comprehensive protection of sensitive information in your NDA agreements.',
    'Seamlessly handle payments directly within the platform, streamlining the entire process from agreement creation to completion.',
    'Safeguard document access and user identity through robust OTP validation, offering additional layers of security via email or Aadhaar verification.',
    ' Experience a seamless signing process with integrated e-signature functionality, enabling swift execution of NDAs without the hassle of printing and scanning.',
    'Enhance the legal validity of your agreements by adding stamp duty directly within the platform, ensuring compliance with regulatory requirements.'
  ] */

  toggle: Array<boolean> = Array(6).fill(false);

  accordianTitle = ['Identification of parties', 'Defining Confidential Information', 'Deciding who all can have access to information', 'Duration Selection', 'Dispute Resolution', 'Optional verification and e-signing', 'Payment via UPI, which you can even split with the other party'];
  accordianContent = ['Explore our offerings: Opt for our free tier to access basic features or upgrade to the premium plan for advanced functionalities and priority support, tailoring your experience to your needs.',
    'Enter into a guided conversation with our intuitive bot, providing key details about your agreement requirements. Our bot will dynamically adjust questions based on your responses, ensuring every aspect of your NDA is covered comprehensively and accurately.',
    'Determine how you want to digitally sign your NDA: Whether you prefer our seamlessly integrated e-signature platform or have another preferred method, such as DocuSign or Adobe Sign, we accommodate your choice, ensuring maximum flexibility and ease of use.',
    'Complete your transaction securely within our platform, with multiple payment options available, including credit/debit cards, PayPal, or other secure payment gateways. Rest assured that your financial information is protected with industry-standard encryption protocols, prioritizing your privacy and security.',
    'Once payment is confirmed, instantly access and download your signed NDA document, formatted and ready for immediate use. Enjoy the convenience of receiving your legally binding agreement promptly, empowering you to proceed confidently with your business dealings.',
    'Ensure authenticity with optional PAN verification for both individuals and companies. Choose a convenient way to eSign your NDA using either Email OTP or Aadhaar eSign. This provides a secure and legally valid way to finalize agreements effortlessly.',
    'Make secure payments through Razorpay, ensuring a smooth transaction process. Parties can choose to split the payment equally or let one party cover the entire cost. This flexibility allows for easy collaboration and hassle-free agreement finalization.'
  ]
  showServices: boolean = true;
  showProcess: boolean = false;
  isMenuOpen: boolean = false;

  constructor() { }

  ngOnInit(): void {

  }

  toggleAccordian(idx: any) {
    this.toggle[idx] = !this.toggle[idx];

  }

  toggleContent(){
    this.showServices = !this.showServices;
    this.showProcess = !this.showProcess;
  }

  toggleMenu(){
    this.isMenuOpen = !this.isMenuOpen;
  }
}
